import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import Widget from "components/Widget/index";
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import TextField from "@material-ui/core/TextField";
import axios from 'axios';
import IntlMessages from 'util/IntlMessages';
import Spinner from 'react-spinner-material';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import PowerCableLength from './PowerCableLength';

const Power = () => {

  const [ open, setOpen] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [powers, setPowers] = useState([]);
  const [powerObj, setPowerObj] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
      getPower();
  }, [])

  const getPower = async () => {
    setVisibility(true)
    axios.get('api/power')
      .then(res => {
        setVisibility(false)
        setPowers(res.data)
      }
      ).catch(err => {
        setVisibility(false)
        NotificationManager.error(<IntlMessages id="notification.errorMessage" />, <IntlMessages
          id="notification.titleHere" />);
      }
      )
  };
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  return (
    <>
      <PowerCableLength open={open} setOpen={setOpen} kw={powerObj?.kw} power_id={powerObj?.id} getPower={getPower}/>
      <Widget>
        <div className="d-flex flex-row mb-2">
            <h4 className="pt-3"> Power Managment </h4>
            <TextField
              id="search"
              name="search"
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{ marginLeft: "auto" }}
              label="Search"
              variant="outlined"
            />
            {/* <IconButton className="ml-auto" color="primary" aria-label="upload picture" component="span"
              onClick={handleClickOpen} >
              <AddCircleOutlineIcon />
            </IconButton>  */}
          </div>
        <span className="row justify-content-center">
          <Spinner radius={60} color={"#3f51b5"} stroke={3} visible={visibility} />
        </span>
        <div className="table-responsive-material">
          <Table className="default-table table-unbordered table table-sm table-hover ">
            <thead className="table-head-sm th-border-b">
              <tr>
                <th>No:</th>
                <th>Power in KW</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {powers?.filter((val) => {
                if (search === "") {
                  return val;
                } else if (
                  val.kw.includes(search)
                ) {
                  return val;
                }
              })
              .map((data, index) => {
                return <tr key={index}>
                  <td>
                    {index + 1}
                  </td>
                  <td>
                    {data.kw} Kw
                  </td>
                  <td>
                    {data.status}
                  </td>
                  <td>
                    <IconButton size="small" color="primary" aria-label="setting an alarm" onClick={() => {
                      setPowerObj(data); setOpen(true);
                    }}>
                      <SettingsIcon />
                    </IconButton>

                  </td>
                </tr>
              })}
            </tbody>
          </Table>

        </div>

      </Widget>
      <NotificationContainer />
    </>
  );
};

export default Power;
