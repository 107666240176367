import React, { useEffect } from "react";
// start import for dialog
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
// import Moment from "react-moment";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      key={"muidialog31"}
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
// end of dialog modal for Solar Panal

export default function DesignerInfo(props) {
  // start code of dialog modal for Solar Panal
  const { openDI, setOpenDI, sFlaq, model, projects } = props;
  const handleClose = () => {
    setOpenDI(false);
  };
  // end code of dialog modal for Solar Panal
  useEffect(() => {
    if (props.projects?.length > 0) {
      projects.forEach((row, index) => {
        row["key"] = index + 1;
      });
    }
  }, [props.projects]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        className="dialogWD"
        fullWidth={"lg"}
        maxWidth={"lg"}
        aria-labelledby="customized-dialog-title"
        open={openDI}
      >
        <DialogTitle
          id="customized-dialog-title"
          className="customizedDialogWaterP mb-4"
          onClose={handleClose}
        ></DialogTitle>
        <DialogContent dividers>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <MaterialTable
                title={`Designed item: ${model}`}
                columns={sFlaq===false?
                  [
                  { title: "PID", field: "id" },
                  { title: "Project Name", field: "name" },
                  { title: "Model", field: "item_model" },
                  { title: "City", field: "geolocation.city" },
                  { title: "Designer Name", field: "user.name" },
                  { title: "Company", field: "user.companyname" },
                  { title: "Email", field: "user.email" },
                  { title: "Phone", field: "user.phone" },
                  { title: "Date", field: "date" },
                  // {
                  //   title: "Date",
                  //   render: (row) => {
                  //     return (
                  //       <Moment format="YYYY/MM/DD">
                  //         {row.created_at}
                  //       </Moment>
                  //     );
                  //   },
                  // },
                ]
                :
                [
                  { title: "PID", field: "id" },
                  { title: "Project Name", field: "name" },
                  { title: "Model", field: "item_model" },
                  { title: "City", field: "geolocation.city" },
                  { title: "Designer Name", field: "user.name" },
                  { title: "Company", field: "user.companyname" },
                  { title: "Email", field: "user.email" },
                  { title: "Phone", field: "user.phone" },
                  { title: "Solar Quantity", field: "solar_quantity" },
                  { title: "Date", field: "date" },
                  
                ]
              }
                data={projects}
                actions={[]}
                options={{
                  actionsColumnIndex: -1,
                  exportButton: true,
                  paging: true,
                  pageSize: 6, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [6, 12, 20, 50, projects.length], // rows selection options
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: (
                      <p color="primary">
                        <strong>No Record Found</strong>
                      </p>
                    ),
                  },
                }}
              />
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            className="jr-btn jr-btn-lg "
            onClick={handleClose}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
