import React, { useState, useEffect } from "react";
// start import for dialog
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Select from '@material-ui/core/Select';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { v4 as uuidv4 } from 'uuid';
// code for small steps
import Slider from '@material-ui/core/Slider';
import axios from 'axios';
import {NotificationManager} from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
// import * as type from 'yup';
// import { checkValidation, runValidation } from './utils';
// end import for dialog 
// start of dialog modal for Solar Panal 
const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle key={'muidialog31'} disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
  // end of dialog modal for Solar Panal 


function valuetext(value) {
  return `${value}KW`;
} 

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(0),
      minWidth: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    root: {
        width: 300,
      },
      
  }));
   
  const marksCL = [
    {
      value: 0,
      label: '0M',
    },
    {
      value: 10000,
      label: '10000M',
    },
  ];

export default function DialogPipeLength(props){
  
  const classes = useStyles();
    // start code of dialog modal for Solar Panal 
    const {openDPL, setOpenDPL, pumpListId, pumpListModel, pipelengths} = props;
    const handleClose = () => {
      setOpenDPL(false);
      
    };
    // end code of dialog modal for Solar Panal 
     
    const [inputFields, setInputFields] = useState([
      { id: uuidv4(), pipeLength: [300, 800], 'pumpListId': pumpListId, pipeLengthId: ''},
    ]);
    useEffect(() => {
      if(props.pumpListId!==0){
        getPipeLengthSettings(props.pumpListId);
        // console.log("pipelengths: ", pipelengths);
      }
    },[props.pumpListId])
    
    const getPipeLengthSettings = async(id) => {
      // console.log("id: ", id);
      // console.log("pipelengths: ", pipelengths);
      if(id!==0 && id!==""){
        // console.log("ok it is not 0", id);
        axios.get('api/pumpPipeLenghtSetting/'+id)
        .then(res => { 
          let mydata = res.data;
          // console.log("the result: "+mydata + "length"+ mydata.length);
          const mainArray = []
          if(mydata.length !== 0){
            mydata.forEach(elem => {
              // console.log(elem); 
              mainArray.push({ id: elem.id, pipeLength: [elem.min_pipe_length,elem.max_pipe_length], pumpListId: elem.pump_list_id, pipeLengthId: elem.pipe_length_id});
            });
            // console.log('mainArray is: ',mainArray);
          }else{
            mainArray.push({ id: uuidv4(), pipeLength: [300, 800], 'pumpListId': id, pipeLengthId: ''});
          }
          setInputFields(mainArray);
        }).catch(err => {
          
            NotificationManager.error(<IntlMessages id="notification.errorMessage"/>, <IntlMessages
                id="notification.titleHere"/>);
            }
          )
      }
    }
    
    // setInputFields(props.setInputFields);
    // inputFields[0].pumpListId = pumpListId;
    const handleChangeStep = (id, event, value, name) => {
      const newInputFields = inputFields.map(i => {
        if(id === i.id) {
          if('pipeLength' === name){
            i['pipeLength'] = value;
          }
          
        }
        return i;
      })
      
      setInputFields(newInputFields);
    }
    const handleChangeInput = (id, event) => {
      const newInputFields = inputFields.map(i => {
        if(id === i.id) {
          i[event.target.name] = event.target.value
        }
        return i;
      })
      
      setInputFields(newInputFields);
    }
    const handleAddFields = () => {
      // console.log(inputFields);
      inputFields[0].pumpListId = pumpListId;
      let newElement = { id: uuidv4(), pipeLength: inputFields[inputFields.length-1].pipeLength, pumpListId: pumpListId, pipeLengthId: inputFields[inputFields.length-1].pipeLengthId};
      setInputFields([...inputFields, newElement])
      // console.log(inputFields);
      // console.log(pumpListId);
    }
    const handleRemoveFields = id => {
      const values  = [...inputFields];
      values.splice(values.findIndex(value => value.id === id), 1);
      setInputFields(values);
    }
    // start get cable type
    // useEffect(() => {
    //   getCabletype();
      
    // },[])

    // const [pipeLengthType,setPipeLengthType]= useState([]);
    // const getCabletype=async () => {
    //   axios.get('api/cabletype')
    //     .then(res => {  
    //       setPipeLengthType(res.data)
    //       }
    //   ).catch(err => {
    //         NotificationManager.error(<IntlMessages id="notification.errorMessage"/>, <IntlMessages
    //             id="notification.titleHere"/>);
    //         }
    //     )
    // };

// end get cable type
    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log("InputFields", inputFields);
        axios.post('api/pumpPipeLenghtSetting', {inputFields: inputFields})
        .then(
            res => {
              // console.log(res);
              // getWaterPDevices();
              NotificationManager.success(<IntlMessages id="notification.successMessage"/>, <IntlMessages
              id="notification.titleHere" />);
              handleClose();
            }
        ).catch(
            err =>{
              NotificationManager.error(<IntlMessages id="notification.errorMessage"/>, <IntlMessages
              id="notification.titleHere"/>);
                console.log(err);
            } 
        )
    }
    let id_field;
    
    return (
      <>
        

        <Dialog onClose={handleClose} className="dialogWD" fullWidth={'md'} maxWidth={'md'} aria-labelledby="customized-dialog-title" open={openDPL}>
           <form autoComplete="off" onSubmit={handleSubmit}>  
            <DialogTitle id="customized-dialog-title" className='customizedDialogWaterP' onClose={handleClose}>
              Pipe Length Settings of {pumpListModel} 
            </DialogTitle>
            <DialogContent dividers>
                {/* <WaterPumpDeviceSettingForm /> */}
                <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              { inputFields.map((inputField, index) => (
                <div key={id_field = inputField.id}>
                    <div className="row paddingBottom pipe_slide">
                         
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 insideFormPaddingWPS inWPS3 inputAdornmentWrap">
                              <Typography id="range-slider" gutterBottom>
                              Pipe Length
                              </Typography>
                              <Slider name="pipeLength" onChange={(event, value) => handleChangeStep(inputField.id, event, value, 'pipeLength')}
                                  value={inputField.pipeLength}
                                  valueLabelDisplay="auto"
                                  aria-labelledby="range-slider"
                                  getAriaValueText={valuetext}
                                  min={0}
                                  max={10000}
                                  marks={marksCL}
                              />
                           
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 insideFormPadding inWPST">
                            <FormControl variant="outlined" size="small" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Pipe Length Type</InputLabel>
                                <Select name="pipeLengthId"
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={inputField.pipeLengthId}
                                onChange={event => handleChangeInput(inputField.id, event)}
                                label="Pipe Length Type"
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {pipelengths?.map(pipeLengthOption => 
                                  <MenuItem value={pipeLengthOption.id}>{pipeLengthOption.outlet}</MenuItem>
                                  )}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                  </div>
                )) }
              </div>
          </div>
            </DialogContent>
            
            <DialogActions>
              <IconButton size="small" color="primary" aria-label="remove alarm" disabled={inputFields.length === 1} onClick={() => handleRemoveFields(id_field)}>
                <RemoveCircleOutlineIcon />
              </IconButton>
              <IconButton size="small" color="primary" aria-label="remove alarm" onClick={handleAddFields}>
                <AddCircleOutlineIcon />
              </IconButton>
              <Button variant="contained" type="submit" color="primary" className="jr-btn jr-btn-lg ">Submit</Button>
            </DialogActions>
          </form>
        </Dialog>
    </>
    );
}
