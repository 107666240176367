import React from 'react';
import {UserList} from "./UserList";
const Usermangment = ({match}) => {
  document.title = "AMO Water Management System - User Management";
  return (
    <div className="app-wrapper">
     <UserList/>
    </div>
  )
};

export default Usermangment;
