import React from 'react';
// import ContainerHeader from "components/ContainerHeader/index";
// import IntlMessages from "util/IntlMessages";
// import VerticalTabs from './VerticalTabs';
import Project from './Project'

const Newproject = ({match}) => {
  document.title = "AMO Water Management System - Sizing";
  return (
  
    <div className="app-wrapper">
    {/* <ContainerHeader match={match} title={<IntlMessages id="New Project" />} />    */}
 
     <Project/>
  </div>
)};

export default Newproject;