import React, { useEffect, useState } from 'react';
import Widget from "components/Widget/index";
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import IntlMessages from 'util/IntlMessages';
import { useForm } from 'react-hook-form';
// Tap import
import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
// End
import { NotificationContainer, NotificationManager } from 'react-notifications';
import './style.css'



const Post = () => {

  const [ranaPost, setRanaPost] = useState();
  const [dorokhshanPost, setDorokhshanPost] = useState();
  const [nonePost, setNonePost] = useState();
  useEffect(() => {
    getPost();
  }, []);

  // start taps functions
  const [value, setValue] = React.useState(0);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  const theme = useTheme();
  const Field = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  // end taps functions
  const getPost = async () => {
    axios.get('api/post')
      .then(res => {
        setDorokhshanPost(res.data[0]);
        setRanaPost(res.data[1]);
        setNonePost(res.data[2]);
      }
      ).catch(err => {

      }
      )
  };

  const { register, handleSubmit } = useForm(); // initialize the hook
  const onSubmit = (data) => {
    // console.log('data test', data);
    axios.put('api/post/' + data.postId, data)
      .then(res => {
        getPost();
        NotificationManager.success(<IntlMessages id="notification.successMessage" />, <IntlMessages
          id="notification.titleHere" />);

      }
      ).catch(err => {
        NotificationManager.error(<IntlMessages id="notification.errorMessage" />, <IntlMessages
          id="notification.titleHere" />);
      }
      )
  };


  return (
    <>

      <Widget styleName={`tableheight`}>
        <div className="">
          <h2 className="center">Post Managment</h2>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={Field}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Rana" {...a11yProps(0)} />
              <Tab label="Dorokhshan" {...a11yProps(1)} />
              <Tab label="None" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction} className="waterPumpPanel">
              <div className="row wp-brand d-flex flex-row">
                <div className="col-xl-12 col-gl-12 col-md-12 col-sm-12 col-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <strong>{ranaPost?.date}</strong>
                    <div className="row">
                      <TextField type='hidden' className='d-none'
                        defaultValue={ranaPost?.id}
                        name="postId" id='postId'
                        inputRef={register} />

                      <TextField id="title" label="Post Title" variant="outlined"
                        placeholder="Post Title"
                        className="mt-3"
                        defaultValue={ranaPost?.title}
                        name="title"
                        size="large"
                        multiline={true}
                        fullWidth={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputRef={register} />

                      <TextField id="discription" label="Post Discription" variant="outlined"
                        placeholder="Post Description"
                        className="mt-3"
                        name="discription"
                        size="large"
                        defaultValue={ranaPost?.discription}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth={true}
                        multiline={true}
                        inputRef={register} />

                    </div>
                    <div className="row mt-4">
                      <Button color="primary" variant="contained" size="medium" type="submit">
                        Update Post
                      </Button>
                    </div>
                  </form>
                </div>
              </div>

            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction} className="waterPumpPanel">
              <div className="row wp-brand d-flex flex-row">
                <div className="col-xl-12 col-gl-12 col-md-12 col-sm-12 col-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <strong>{dorokhshanPost?.date}</strong>
                    <div className="row">
                      <TextField className='d-none' defaultValue={dorokhshanPost?.id}
                        name="postId" id='postId' inputRef={register} />

                      <TextField id="title" label="Post Title" variant="outlined"
                        placeholder="Post Title"
                        className="mt-3"
                        defaultValue={dorokhshanPost?.title}
                        name="title"
                        size="large"
                        multiline={true}
                        fullWidth={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputRef={register} />

                      <TextField id="discription" label="Post Discription" variant="outlined"
                        placeholder="Post Description"
                        className="mt-3"
                        name="discription"
                        size="large"
                        defaultValue={dorokhshanPost?.discription}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth={true}
                        multiline={true}
                        inputRef={register} />

                    </div>
                    <div className="row mt-4">
                      <Button color="primary" variant="contained" size="medium" type="submit">
                        Update Post
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction} className="waterPumpPanel">
              <div className="row wp-brand d-flex flex-row">
                <div className="col-xl-12 col-gl-12 col-md-12 col-sm-12 col-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <strong>{nonePost?.date}</strong>
                    <div className="row">
                      <TextField className='d-none' defaultValue={nonePost?.id}
                        name="postId" id='postId' inputRef={register} />

                      <TextField id="title" label="Post Title" variant="outlined"
                        placeholder="Post Title"
                        className="mt-3"
                        defaultValue={nonePost?.title}
                        name="title"
                        size="large"
                        multiline={true}
                        fullWidth={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputRef={register} />

                      <TextField id="discription" label="Post Discription" variant="outlined"
                        placeholder="Post Description"
                        className="mt-3"
                        name="discription"
                        size="large"
                        defaultValue={nonePost?.discription}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth={true}
                        multiline={true}
                        inputRef={register} />

                    </div>
                    <div className="row mt-4">
                      <Button color="primary" variant="contained" size="medium" type="submit">
                        Update Post
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </TabPanel>
          </SwipeableViews>


        </div>



      </Widget>
      <NotificationContainer />
    </>
  );
};

export default Post;
