import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

// form dependency
import { TextField } from '@material-ui/core';
import axios from 'axios';
import { useForm } from 'react-hook-form';
//drop zoon
import { useDropzone } from 'react-dropzone';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import IntlMessages from 'util/IntlMessages';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 0
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: '-webkit-fill-available',
  height: 190,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const dropzone1 = {

//   height: '100px',
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '15px',
  borderWidth: '2px',
  borderRadius: '2px',
  borderColor: '#3548d9',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#3548d9',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function SliderImageDialog(props) {
  const { register, handleSubmit, errors } = useForm(); // initialize the hook
  const { open, setOpen, setGetData } = props;
  const { sliderImgObj, setSliderImgObj } = props;
 
  const handleClose = () => {
    setSliderImgObj(null);
    setFiles([]);
    setGetData(true);
    setOpen(false);
  };

  //drop down

  //drop zone
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt="previews"
        />
      </div>
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const onSubmit = (data) => {
    // console.log('data in post form', data);
    var id = JSON.parse(localStorage.getItem("UserData"))?.id;
    data['user_id'] = id;
    if (files.length !== 0) {
      var slider_image = '';
      let file = files[0];
      let reader = new FileReader();
      reader.onloadend = (file) => {
        slider_image = reader.result;
        data['slider_image'] = slider_image;
        axios.post('api/sliderImages', data)
          .then(res => {
            NotificationManager.success(<IntlMessages id="notification.successMessage" />, <IntlMessages
              id="notification.titleHere" />);
            setGetData(false);
            setOpen(false)
          }
          ).catch(err => {
            NotificationManager.error(<IntlMessages id="notification.errorMessage" />, <IntlMessages
              id="notification.titleHere" />);
          }

          )
      }
      reader.readAsDataURL(file);
    } else {
      data['slider_image'] = 'oldImage';
      axios.post('api/sliderImages', data)
        .then(res => {
          NotificationManager.success(<IntlMessages id="notification.successMessage" />, <IntlMessages
            id="notification.titleHere" />);
          setGetData(false);
          setOpen(false)
        }
        ).catch(err => {
          NotificationManager.error(<IntlMessages id="notification.errorMessage" />, <IntlMessages
            id="notification.titleHere" />);
        }

        )
    }
  };


  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="xs" fullWidth="xs">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Slider Image Form
        </DialogTitle>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers>
            <div className="row">
              <div className="col-xl-12 col-gl-12 col-md-12 col-sm-12 col-12 py-3">
                    <TextField id="id" type='hidden' name="id" defaultValue={(sliderImgObj?.id) ? sliderImgObj?.id : 0} inputRef={register} />
                    <TextField id="title" className="form-control" name="title" defaultValue={sliderImgObj?.title} label="Title" size="small" variant="outlined" inputRef={register({ required: true })}
                      error={errors.title && true} helperText={errors.title && '*required'}
                    />
              </div>
              <div className="col-xl-12 col-gl-12 col-md-12 col-sm-12 col-12 py-3">
                <section className="container p-0">
                  <div {...getRootProps({ className: 'dropzone' })} style={dropzone1}>
                    <input {...getInputProps()} />
                    <p class='m-0'>Uplod Image</p>
                  </div>
                  <aside style={thumbsContainer}>
                    {thumbs}
                    {(files.length === 0) ? ((sliderImgObj?.src !== "" && sliderImgObj?.src !== undefined) ? (<spam>
                      <span className={`sp_right_padding`}>Cuurent Image </span>
                      <span><img src={`${axios.defaults.baseURL}slider_image/${sliderImgObj?.src}`} class="img-thumbnail rounded" alt="Responsive"></img></span>
                    </spam>) : '') : ''}
                  </aside>
                </section>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary" variant="contained" className="pull-right">
              Save Slider Image
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <NotificationContainer />
    </div>
  );
}
