import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import Widget from "components/Widget/index";

import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import axios from "axios";
import { useForm } from "react-hook-form";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
// import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
import Swal from "sweetalert2";
import IntlMessages from "util/IntlMessages";
import Spinner from "react-spinner-material";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "./style.css";

const CableType = () => {
  const [addvisibility, setAddvisibility] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [cabletypeObj, setCabletypeObj] = useState([]);
  const [cabletype, setCabletype] = useState([]);
  useEffect(() => {
    getCabletype();
  }, []);

  const getCabletype = async () => {
    setVisibility(true);
    axios
      .get("api/cabletype")
      .then((res) => {
        setVisibility(false);
        setCabletype(res.data);
      })
      .catch((err) => {
        setVisibility(false);
        NotificationManager.error(
          <IntlMessages id="notification.errorMessage" />,
          <IntlMessages id="notification.titleHere" />
        );
      });
  };

  const { register, errors, handleSubmit } = useForm(); // initialize the hook
  const onSubmit = (data) => {
    axios
      .post("api/cabletype", data)
      .then((res) => {
        setAddvisibility(false);
        setCabletype(res.data);
        NotificationManager.success(
          <IntlMessages id="notification.successMessage" />,
          <IntlMessages id="notification.titleHere" />
        );
      })
      .catch((err) => {
        NotificationManager.error(
          <IntlMessages id="notification.errorMessage" />,
          <IntlMessages id="notification.titleHere" />
        );
      });
  };

  const deletCabletype = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete("api/cabletype/" + id)
          .then((res) => {
            // setCabletype(res.data)
            setCabletype(cabletype.filter((value) => value.id !== id));
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      }
    });
  };

  const editCabletype = (data) => {
    setAddvisibility(true);
    setCabletypeObj(data);
  };
  return (
    <>
      <Widget styleName={`tableheight`}>
        <div className="d-flex flex-row mb-2">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => setAddvisibility(true)}
            hidden={addvisibility === true}
          >
            <AddCircleOutlineIcon />
          </IconButton>
          <span hidden={addvisibility === true} style={{ padding: '12px' }}><h2 class="center m-0">Cable Type Managment</h2></span>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            hidden={addvisibility === false}
            onClick={() => {
              setAddvisibility(false);
              setCabletypeObj([]);
            }}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
          {addvisibility ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-12 mb-2">
                  <TextField
                    id="id"
                    type="hidden"
                    style={{ width: "0%" }}
                    name="cableTypeID"
                    defaultValue={cabletypeObj?.id ? cabletypeObj?.id : ""}
                    inputRef={register}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Cable type"
                    variant="outlined"
                    placeholder="Cable type"
                    name="name"
                    size="small"
                    fullWidth
                    defaultValue={cabletypeObj?.name}
                    error={errors.name && true}
                    helperText={errors.name && "*required"}
                    inputRef={register({ required: true })}
                  />
                </div>
                <div className="col-md-12 mb-2">
                  <TextField
                    id="outlined-basic-price"
                    size="small"
                    fullWidth
                    className="fullWidthInput"
                    label="Price"
                    name="price"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">$</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={cabletypeObj?.price}
                    inputRef={register({
                      required: true,
                      pattern: /^[+-]?([0-9]*[.])?[0-9]+$/,
                    })}
                    error={errors.price && true}
                    helperText={
                      errors.price ? "*Please enter valid number." : ""
                    }
                  />
                </div>
              </div>

              <div className="col-md-3 col-sm-3 col-lg-3 col-xs-12 pl-0">
                <Button
                  color="primary"
                  variant="contained"
                  size="medium"
                  type="submit"
                >
                  <AddCircleOutlineIcon />
                </Button>
              </div>
            </form>
          ) : null}
        </div>

        <span className="row justify-content-center">
          <Spinner
            radius={60}
            color={"#3f51b5"}
            stroke={3}
            visible={visibility}
          />
        </span>
        <div className="table-responsive-material">
          <Table className="default-table table-unbordered table table-sm table-hover ">
            <thead className="table-head-sm th-border-b">
              <tr>
                <th>No:</th>
                <th>Name</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {cabletype.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data.name}</td>
                    <td>
                      {data.price} {data.price ? "$" : ""}
                    </td>
                    <td>
                      <IconButton
                        size="small"
                        aria-label="Delete"
                        color="secondary"
                        onClick={() => {
                          deletCabletype(data.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        aria-label="Edit"
                        color="primary"
                        disabled={addvisibility}
                        onClick={() => editCabletype(data)}
                      >
                        <EditIcon />
                      </IconButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Widget>
      <NotificationContainer />
    </>
  );
};

export default CableType;
