import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Button from '@material-ui/core/Button';
import axios from "axios";
import VideoDialog from '../setting/FileUpload/VideoDialog'
import Card from '@material-ui/core/Card';
// import CardHeader from '@material-ui/core/CardHeader';
// import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
// import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
// import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import { red } from '@material-ui/core/colors';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
// import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345,
  },
  // media: {
  //   height: 0,
  //   paddingTop: '56.25%', // 16:9
  // },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    float: 'right',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  // avatar: {
  //   backgroundColor: red[500],
  // },
  h150:{
    maxHeight: '150px',
    borderRadius: 10,
    '& > img':{
      borderRadius: 10,
    }
  },
  des:{
    '& > p.text-muted':{
      minHeight: 42
    }
  },
  body:{
    '& > .MuiTypography-body1':{
      fontSize: '16px !important'
    }
  },
  button: {
    // margin: theme.spacing(1),
    // float: 'right',
  },
}));
const MAX_LENGTH = 250;
export default function Tutorail({data}) {
  const {image, name, type, language, video_type, file_src, file_size, created_at, description} = data;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [openVideo, setOpenVedio] = React.useState({
    open:false, path:'', name:'', videoType:''
  });
  const formatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "long",
    day: "2-digit"
  });
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
 
  return (
    <>
    <VideoDialog
        openVideo={openVideo}
        setOpenVedio={setOpenVedio}
    />
    <Card className={`${classes.root} mb-3`}>
      {/* <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            R
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title="Shrimp and Chorizo Paella"
        subheader="September 14, 2016"
      />
      <CardMedia
        className={classes.media}
        image="https://via.placeholder.com/500x330"
        title="Paella dish"
      /> */}
      <CardContent className="pb-0">
      <div className={`row`}>
         
        <div className={`col-md-3 br-10 mb-3 ${classes.h150}`}>
          <img alt="..." src={`${axios.defaults.baseURL}tutorial/image/${image}`} class="MuiAvatar-img" />
        </div>
        <div className={`description col-md-9 ${classes.des}`}>
          <div className='row'>
            <div className='col-md-9'>
              <h3>{name}</h3>
              <h5>{formatter.format(new Date(created_at))}</h5>
              <h6>Language: {language}</h6>
            </div>
            <div className='col-md-3 pl-5'>
              {type!='none'?
              <h6 >Size: {file_size>=1024576?Number(file_size/1024576).toFixed(2)+ ' MB':file_size>=1024?Number(file_size/1024).toFixed(2)+ ' KB':' Bytes'}</h6> :''
              }
              {type==='pdf'?
                <a disabled={file_src?false:true} href={`${axios.defaults.baseURL}tutorial/${file_src}`} target="_blank">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<PictureAsPdfIcon />}
                  >
                    Download
                  </Button>
                </a> :
                type==='video'?
                <>
                  {/* <IconButton
                    size="small"
                    aria-label="Play"
                    color="primary"
                    onClick={() => setOpenVedio({
                    ...openVideo, open:true, path:file_src, name:name, videoType:video_type
                  })}
                  >
                    <PlayCircleFilledIcon />
                  </IconButton> */}
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<PlayCircleFilledIcon />}
                    onClick={() => setOpenVedio({
                      ...openVideo, open:true, path:file_src, name:name, videoType:video_type
                    })}
                  >
                    View
                  </Button>
                </>
                :''
              }
            </div>
          </div>
          
          {description?.length > MAX_LENGTH ?
                (
                  <p className="text-muted">{`${description.substring(0, MAX_LENGTH)}...`}<a href="#" onClick={handleExpandClick}>{expanded?'Read less':'Read more'}</a></p>
                ) :
                <p className="text-muted">{description}</p>
          }
          <div>
           
            
            {/* <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton> */}
          </div>
        </div>
      </div>
      </CardContent>
      {/* <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions> */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.body}>
          <Typography paragraph className='f16'>Details:</Typography>
          <Typography paragraph>
             {description}
          </Typography>
           
        </CardContent>
      </Collapse>
    </Card>
     
     </>
  );
}
