import React, { useEffect, useState } from 'react';
import Tutorail from "./Tutorail";
import axios from "axios";
import IntlMessages from "util/IntlMessages";
import Spinner from "react-spinner-material";
import TextField from "@material-ui/core/TextField";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const TutorailPage = () => {
  const [search, setSearch] = useState("");
  const [tutorials, setTutorials] = useState([]);
  const [visibility, setVisibility] = useState(false);
  useEffect(() => {
      getTutorial();
  }, []);
  const getTutorial = async () => {
  setVisibility(true);
  axios
    .get("api/tutorials-all")
    .then((res) => {
      setVisibility(false);
      setTutorials(res.data);
    })
    .catch((err) => {
      setVisibility(false);
      NotificationManager.error(
        <IntlMessages id="notification.errorMessage" />,
        <IntlMessages id="notification.titleHere" />
      );
    });
  };
  return (
    <div className='app-wrapper knowledge-section' id="callouts">
      {visibility?
        <span className="row justify-content-center">
          <Spinner
            radius={60}
            color={"#3f51b5"}
            stroke={3}
            visible={visibility}
          />
        </span> :

        <div className="animated slideInUpTiny animation-duration-3">
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-5 col-12 center m-a">
              <div className="jr-card jr-card-widget card p-2 mb-3">
                <TextField
                  id="search"
                  name="search"
                  size="small"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ textAlign: "center", margin:'auto', width:'100%' }}
                  label="Search"
                  variant="outlined"
                />
              </div>
            </div>
          </div>
            
            {tutorials
            .filter((val) => {
              if (search === "") {
                return val;
              } else if (
                val.type.toString().toLowerCase().includes(search.toString().toLowerCase()) ||
                val.name.toString().toLowerCase().includes(search.toString().toLowerCase()) ||
                val.description.toString().toLowerCase().includes(search.toString().toLowerCase()) ||
                val.language.toString().toLowerCase().includes(search.toString().toLowerCase())
              ) {
                return val;
              }
            }).map((data, index) => (
            <Tutorail key={index} data={data} />
            ))}
        </div>
        }
      <NotificationContainer />
    </div>
  )
};

export default TutorailPage;

