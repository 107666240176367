import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import Widget from "components/Widget/index";

import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
// import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
import Swal from "sweetalert2";
import IntlMessages from "util/IntlMessages";
import Spinner from "react-spinner-material";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "./style.css";
import SliderImageDialog from "./SliderImageDialog";

const PipeLenght = () => {
  const [visibility, setVisibility] = useState(false);
  const [open, setOpen] = useState(false);
  const [getData, setGetData] = useState(false);
  const [sliderImgObj, setSliderImgObj] = useState([]);
  const [sliderImages, setSliderImages] = useState([]);
  useEffect(() => {
    if (!open && !getData) {
        getSliderImage();
    }
  }, [open, getData]);

  const getSliderImage = async () => {
    setVisibility(true);
    axios
      .get("api/sliderImages")
      .then((res) => {
        setVisibility(false);
        setSliderImages(res.data);
      })
      .catch((err) => {
        setVisibility(false);
        NotificationManager.error(
          <IntlMessages id="notification.errorMessage" />,
          <IntlMessages id="notification.titleHere" />
        );
      });
  };

  const deleteSliderImage = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete("api/sliderImages/" + id)
          .then((res) => {
            // setSliderImages(res.data)
            setSliderImages(sliderImages.filter((value) => value.id !== id));
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      }
    });
  };

  const editSliderImage = (data) => {
    setOpen(true);
    setSliderImgObj(data);
  };
  return (
    <>
        <SliderImageDialog
            open={open}
            setOpen={setOpen}
            setGetData={setGetData}
            sliderImgObj={sliderImgObj}
            setSliderImgObj={setSliderImgObj}
        />
      <Widget styleName={`tableheight`}>
        <div className="d-flex flex-row mb-2">
          <IconButton
            color="primary"
            aria-label="Add Slider Image"
            component="span"
            onClick={() => setOpen(true)}
            hidden={open === true}
          >
            <AddCircleOutlineIcon />
          </IconButton>
          <span hidden={open === true} style={{ padding: '12px' }}><h2 class="center m-0">Slider Image Managment</h2></span>
        </div>

        <span className="row justify-content-center">
          <Spinner
            radius={60}
            color={"#3f51b5"}
            stroke={3}
            visible={visibility}
          />
        </span>
        <div className="table-responsive-material">
          <Table className="default-table table-unbordered table table-sm table-hover ">
            <thead className="table-head-sm th-border-b">
            <tr>
                <th width="10%">No:</th>
                <th width="40%">Title</th>
                <th width="40%">Image</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
              {sliderImages.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data.title}</td>
                    <td>
                        <div className="d-flex align-items-center">
                            <img src={`${axios.defaults.baseURL}slider_image/${data.src}`}  class="img-thumbnail rounded acc_img_width1"  alt="Responsive" />
                        </div>
                    </td>
                    <td>
                      <IconButton
                        size="small"
                        aria-label="Delete"
                        color="secondary"
                        onClick={() => {
                          deleteSliderImage(data.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        aria-label="Edit"
                        color="primary"
                        disabled={open}
                        onClick={() => editSliderImage(data)}
                      >
                        <EditIcon />
                      </IconButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Widget>
      <NotificationContainer />
    </>
  );
};

export default PipeLenght;
