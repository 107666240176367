import React from 'react';

// import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import CardBox from 'components/CardBox/index';

import GeoLocation from "./GeoLocation"; 
import WaterPump from "./WaterPump";
import SolarPanel from "./SolarPanel";
import Accessories from "./Accessories";
import Structure from './Structure';
import Power from './Power';
import Invertor from "./Invertor";
import Divider from '@material-ui/core/Divider';

import './style.css';

const Systemmangment = ({match}) => 
{
  document.title = "AMO Water Management System - System Management";
  return (
    <div className="app-wrapper system-section">
      {/* <ContainerHeader match={match} title={<IntlMessages id="System Mangment"/>} />  */}
      <h3><b>Geo-Location Mangment</b></h3>
      <span>
        <GeoLocation key={'geolocation'}/>
      </span>
      <Divider className='diver-sys' /> <br/>

      <h3><b>Water Pumps</b></h3>
      <span>
        <WaterPump key={'waterpump'} />
      </span>
      <Divider  className='diver-sys' /><br/>

      <h3><b>Solar Panels</b></h3>
      <span>
        <SolarPanel  key={'solarpanel'} />
      </span>
      <Divider  className='diver-sys' /><br/>

      <h3><b>Inverters</b></h3>
      <span>
        <Invertor  key={'ivnertor'} />
      </span>
      <Divider  className='diver-sys' /><br/>
      <div className="row mb-md-3">
        <CardBox styleName="col-12" cardStyle="p-0" heading={<IntlMessages id="Accessories"/>}
                  headerOutside>
          <Accessories  key={'accessories'} />
        </CardBox>

      </div>
      <Divider  className='diver-sys' /><br/>
      <div className="row mb-md-3">
        <div className="col-xl-6 col-lg-6 col-md-6 col-12 structure-table wp-second-col">
          <Structure  key={'structure'} />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-12 wp-second-col">
          <Power  key={'power'} />
        </div>
      </div>
  </div>
)
};

export default Systemmangment;