import React, { useEffect, useState } from "react";
import axios from "axios";
import MaterialTable from "material-table";
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import {DatePicker} from 'material-ui-pickers';
import Button from "@material-ui/core/Button";
// import { browserHistory } from 'react-router';
//back drop and notification
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import DesignerInfo from "./DesignerInfo";
//classes
 
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const SalingItem = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  
  const [pumpLists, setPumpLists] = useState([]);
  const [solarLists, setSolarLists] = useState([]);
  const [inverterLists, setInverterLists] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(moment());

  const [openDI, setOpenDI] = React.useState(false);
  const [sFlaq, setSFlaq] = React.useState(false);
  const [model, setModel] = React.useState('');
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const getSalingItem = async () => {
      setOpen(true);
      var id = JSON.parse(localStorage.getItem("UserData")).id;
      var system = JSON.parse(localStorage.getItem("UserData")).system;
      var belongto = JSON.parse(localStorage.getItem("UserData")).belongto;
      var data = [id, system, belongto, '2018-01-01 00:00:00', moment().format('YYYY-MM-DD hh:mm:ss')];
      axios
        .post("api/get-saling-item", data)
        .then((res) => {
          if (res.data.auth == "unauthenticated") {
            localStorage.removeItem("token");
            this.props.history.push("/signin");
          } else {
            setOpen(false);
            setPumpLists(res.data.pump_lists);
            setSolarLists(res.data.solar_lists);
            setInverterLists(res.data.inverter_lists);
          }
        })
        .catch((err) => {
          setOpen(false);
          NotificationManager.error(
            <IntlMessages id="notification.errorMessage" />,
            <IntlMessages id="notification.titleHere" />
          );
        });
    };
    getSalingItem();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(true);
      var id = JSON.parse(localStorage.getItem("UserData")).id;
      var system = JSON.parse(localStorage.getItem("UserData")).system;
      var belongto = JSON.parse(localStorage.getItem("UserData")).belongto;
      var data = [id, system, belongto, startDate.format('YYYY-MM-DD hh:mm:ss'), endDate.format('YYYY-MM-DD hh:mm:ss')];
      axios
        .post("api/get-saling-item", data)
        .then((res) => {
          if (res.data.auth == "unauthenticated") {
            localStorage.removeItem("token");
            this.props.history.push("/signin");
          } else {
            setOpen(false);
            setPumpLists(res.data.pump_lists);
            setSolarLists(res.data.solar_lists);
            setInverterLists(res.data.inverter_lists);
          }
        })
        .catch((err) => {
          setOpen(false);
          NotificationManager.error(
            <IntlMessages id="notification.errorMessage" />,
            <IntlMessages id="notification.titleHere" />
          );
        });
  }

  const handleStartDateChange = (date) => {
    setStartDate(date);
    // console.log('StartDate: ', date.format('YYYY-MM-DD hh:mm:ss'));
    // console.log('StartDate: ', new Date(date._d).toISOString());
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
    // console.log('EndDate: ', date._d);
  };
  return (
    <>
      {open ? (
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div>
          <DesignerInfo openDI={openDI}  setOpenDI={setOpenDI} sFlaq={sFlaq} model={model} projects={projects}></DesignerInfo>

          <Divider  className='diver-sys mt-4' /><br/>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="jr-card jr-card-widget card">
              <div className="row">
                <div className="col-xl-5 col-lg-5 col-md-5 col-12 py-2">
                  <div key="basic_day" className="picker">
                    <DatePicker
                      fullWidth
                      inputVariant='outlined'
                      label="Start Date"
                      format='YYYY-MM-DD'
                      value={startDate}
                      maxDate={endDate}
                      onChange={(e)=>handleStartDateChange(e)}
                      animateYearScrolling={false}
                      leftArrowIcon={<i className="zmdi zmdi-arrow-back"/>}
                      rightArrowIcon={<i className="zmdi zmdi-arrow-forward"/>}
                    />
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-5 col-12 py-2">
                  <div key="basic_day" className="picker">
                    <DatePicker
                      fullWidth
                      inputVariant='outlined'
                      label="End Date"
                      format='YYYY-MM-DD'
                      value={endDate}
                      maxDate={moment()}
                      onChange={(e)=>handleEndDateChange(e)}
                      animateYearScrolling={false}
                      leftArrowIcon={<i className="zmdi zmdi-arrow-back"/>}
                      rightArrowIcon={<i className="zmdi zmdi-arrow-forward"/>}
                    />
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-12 py-2">
                  <Button type='submit' color="primary" variant="contained" size='large' className='pt-2'>
                    Filter
                  </Button>
                </div>
              </div>
            </div>
          </form>
          <MaterialTable
            title="Designed Pump List"
            columns={
                [
                  { title: "Id", field: "key" },
                  { title: "Brand", field:'pump_brand.name'},
                  { title: "Made In",  field:'pump_brand.country' },
                  { title: "Name/Model", field: "model" },
                  { title: "QTY/Designed", field: "total_sale" },
                  { title: "Current(A)", field: "ampeier" },
                  { title: "Diameter(Inch)", field: "diameter" },
                  { title: "Power(KW)", field: "r_power.kw" },
                ]
            }
            data={pumpLists}
            actions={[
              (rowData) => ({
                icon: "visibility",
                color: "primary",
                tooltip: "User Info",
                onClick: (event, rowData) => {
                  // console.log('row data: ', rowData);
                  setProjects(rowData.project);
                  setOpenDI(true);
                  setSFlaq(false);
                  setModel(rowData?.pump_brand.name+' '+rowData?.model+'--- from:'+(startDate?startDate?.format('YYYY-MM-DD hh:mm:ss'):'01-01-2021')+'--- up to: '+endDate?.format('YYYY-MM-DD'));
                },
              }),
            ]}
            options={{
              actionsColumnIndex: -1,
              exportButton: true,
              paging:true,
              pageSize:6,       // make initial page size
              emptyRowsWhenPaging: false,   // To avoid of having empty rows
              pageSizeOptions:[6,12,20,50, pumpLists.length],    // rows selection options
            }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <p color="primary">
                    <strong>No Record Found</strong>
                  </p>
                ),
              },
            }}
          />
          <Divider  className='diver-sys mt-4' /><br/>

          <MaterialTable
            title="Designed Solar List"
            columns={
                [
                  { title: "Id", field: "key" },
                  { title: "Brand", field:'solar_brand.name'},
                  { title: "Made In",  field:'solar_brand.country' },
                  { title: "Name/Model", field: "model" },
                  { title: "QTY/Designed", field: "total_sale" },
                  { title: "Power(W)", field: "power" },
                  { title: "Voltage(Voc)", field:'voltage' },
                  { title: "Current(Isc)", field: "current" },
                  // { title: "Voltage(Vmp)", field:'voltageVmp' },
                  // { title: "Current(Imp)", field: "currentImp" },
                  { title: "Weight(Kg)", field: "weight" },
                ]
            }
            data={solarLists}
            actions={[
              (rowData) => ({
                icon: "visibility",
                color: "primary",
                tooltip: "User Info",
                onClick: (event, rowData) => {
                  // console.log('row data: ', rowData);
                  setProjects(rowData.project);
                  setOpenDI(true);
                  setSFlaq(true);
                  setModel(rowData?.solar_brand.name+' '+rowData?.model+'--- from:'+(startDate?startDate?.format('YYYY-MM-DD hh:mm:ss'):'01-01-2021')+'--- up to: '+endDate?.format('YYYY-MM-DD'));
                },
              }),
            ]}
            options={{
              actionsColumnIndex: -1,
              exportButton: true,
              paging:true,
              pageSize:6,       // make initial page size
              emptyRowsWhenPaging: false,   // To avoid of having empty rows
              pageSizeOptions:[6,12,20,50, solarLists.length],    // rows selection options
            }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <p color="primary">
                    <strong>No Record Found</strong>
                  </p>
                ),
              },
            }}
          />
          <Divider  className='diver-sys mt-4' /><br/>

          <MaterialTable
            title="Designed Inverter List"
            columns={
                [
                  { title: "Id", field: "key" },
                  { title: "Brand", field:'invertor_brand.name'},
                  { title: "Made In",  field:'invertor_brand.country' },
                  { title: "Name/Model", field: "model" },
                  { title: "QTY/Designed", field: "total_sale" },
                  { title: "Power(KW)", field: "power" },
                  { title: "Voltage(V)", field:'voltage' },
                  { title: "Current(A)", field: "current" },
                  // {
                  //   title: "Voltage",
                  //   render: (inverterLists) => {
                  //     return `${inverterLists.voltage_ac_min}-${inverterLists.voltage_ac_max} AC`;
                  //   },
                  // },
                  // {
                  //   title: "Voltage",
                  //   render: (inverterLists) => {
                  //     return `${inverterLists.voltage_dc_min}-${inverterLists.voltage_dc_max} DC`;
                  //   },
                  // },
                  // { title: "Weight(Kg)", field: "weight" },
                ]
            }
            data={inverterLists}
            actions={[
              (rowData) => ({
                icon: "visibility",
                color: "primary",
                tooltip: "User Info",
                onClick: (event, rowData) => {
                  // console.log('row data: ', rowData);
                  setProjects(rowData.project);
                  setOpenDI(true);
                  setSFlaq(false);
                  setModel(rowData?.invertor_brand.name+' '+rowData?.model+'--- from:'+(startDate?startDate?.format('YYYY-MM-DD hh:mm:ss'):'01-01-2021')+'--- up to: '+endDate?.format('YYYY-MM-DD'));
                },
              }),
            ]}
            options={{
              actionsColumnIndex: -1,
              exportButton: true,
              paging:true,
              pageSize:6,       // make initial page size
              emptyRowsWhenPaging: false,   // To avoid of having empty rows
              pageSizeOptions:[6,12,20,50, inverterLists.length],    // rows selection options
            }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <p color="primary">
                    <strong>No Record Found</strong>
                  </p>
                ),
              },
            }}
          />
        </div>
      )}
      <NotificationContainer />
    </>
  );
};
