import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Button from "@material-ui/core/Button";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  NotificationManager,
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
//slider
import CardBox from "components/CardBox";
import Sliderr from "react-slick";
//dialog
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
//country flag
import Flags from "country-flag-icons/react/3x2";
import Spinner from "react-spinner-material";
import PropTypes from "prop-types";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import Help from "@material-ui/icons/Help";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import FlashAutoIcon from "@material-ui/icons/FlashAuto";
import StepConnector from "@material-ui/core/StepConnector";
import clsx from "clsx";
import BrandDescription from './BrandDescription';
import axios from "axios";
import {Table} from 'reactstrap';
 
 // start code of brand stepper
 const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
function getFlag(countryname) {
  switch (countryname) {
    case "Afghanistan":
      return <Flags.AF title="Afghanistan" className="customflag" />;
    case "Italy":
      return <Flags.IT title="Italy" className="customflag" />;
    case "China":
      return <Flags.CH title="China" className="customflag" />;
    case "Iran":
      return <Flags.IR title="Iran" className="customflag" />;
    case "Turkey":
      return <Flags.TR title="Turkey" className="customflag" />;
    case "Germany":
      return <Flags.DE title="Germany" className="customflag" />;
    default:
      return "";
  }
}
 //slider
const options = {
  dots: true,
  arrows: true,
  infinite: false,
  speed: 300,
  slidesToShow: 3,
  marginRight: 5,
  slidesToScroll: 1,

  responsive: [
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
  ],
};

 const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <LocalDrinkIcon />,
    2: <WbSunnyIcon />,
    3: <FlashAutoIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};
const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});
 //dialog
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

//end dialog
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function Selectbrand(props) {
  // start brand stepper state code
  const {open, setOpen, solar, solarSelectWatt, setSolarSelectWatt, pump, invertor,
          solarvalue, setSolarvalue, pumpvalue, setPumpvalue, invertorvalue, setInvertorvalue,
          solarstate, setSolarstate, pumpstate, setPumpstate, invertorstate, setInvertorstate
        } = props;

  const [visibility, setVisibility] = useState(false);

  const [solarWatts, setSolarWatts] = useState([]);

  const [activeStepBrand, setActiveStepBrand] = useState(0);
  const [openSolarPower, setOpenSolarPower] = useState(false);

  const [openBD, setOpenBD] = useState(false);
  const [brandDescription, setBrandDescription] = useState('');
  const classes = useStyles();

  const handleClose = () => {
    if(pumpvalue && solarvalue && invertorvalue){
      setOpen(false);
    }
  };
  function getStepsBrand() {
    return [
      "Select Water Pump Brand",
      "Select Solar Brand",
      "Select Inverter Brand",
    ];
  }

  const stepsBrand = getStepsBrand();
  const handleNextBrand = () => {
    setActiveStepBrand((prevActiveStepBrand) => prevActiveStepBrand + 1);
  };

  const handleBackBrand = () => {
    setActiveStepBrand((prevActiveStepBrand) => prevActiveStepBrand - 1);
  };

  const handleResetBrand = () => {
    setActiveStepBrand(0);
  };
  // end brand stepper State code 
  
  // const [solarbrandname, setSolarbrandname] = useState();
  const solarbrand = (id, name, index) => {
    setSolarstate(index);
    setSolarvalue(id);
    // setSolarbrandname(name);
  };
  // const [pumpbrandname, setPumpbrandname] = useState();
  const pumpbrand = (id, name, index) => {
    setPumpstate(index);
    setPumpvalue(id);
    // setPumpbrandname(name);
  };

  // const [invertorbrandname, setInvertorbrandname] = useState();
  const invertorbrand = (id, name, index) => {
    setInvertorstate(index);
    setInvertorvalue(id);
    // setInvertorbrandname(name);
  };

  function toggelactivestyle(index, id) {
    if (solarstate === index) {
      return "activebrand brand-logo";
    } else {
      return "brand-logo";
    }
  }

  function toggelactivestylepump(index) {
    if (pumpstate === index) {
      return "activebrand brand-logo";
    } else {
      return "brand-logo";
    }
  }

  function toggelactivestyleinvertor(index) {
    if (invertorstate === index) {
      return "activebrand brand-logo";
    } else {
      return "brand-logo";
    }
  }
  //end solar and brand

  const getSolarWatts = (id) => {
    setVisibility(true);
    axios.get("api/getSolarWatt/" + id)
      .then((res) => {
        setSolarWatts(res.data);
        setOpenSolarPower(true);
        setVisibility(false);
      })
      .catch((err) => {
        setVisibility(false);
        NotificationManager.error(
          <IntlMessages id="notification.errorMessageSolarList" />,
          <IntlMessages id="notification.titleHereSolarList" />
        );
      });
  };
  const handleCloseP = () => {
    if(solarWatts[0]){
      if(solarSelectWatt){
        setOpenSolarPower(false);
      }
    }else{
      setOpenSolarPower(false);
    }
    
  };
  const handleChangeP = (id, value) => {
    // console.log('pumpBrand', value);
    const newSolarWatts = solarWatts.map(i => {
      if(i.id === id){
        i.checked = value? "true":"false";
        setSolarSelectWatt(id);
      }else{
        i.checked = "false";
      }
      return i;
    })
    setSolarWatts(newSolarWatts);
  };
  return (
    <div>
      <BrandDescription openBD={openBD} setOpenBD={setOpenBD} brandDescription={brandDescription} />
      <Dialog onClose={handleCloseP} aria-labelledby="customized-dialog-title" open={openSolarPower} maxWidth="sm" fullWidth="sm">
        <DialogTitle id="customized-dialog-title" onClose={handleCloseP}>
          Solar Power Details
        </DialogTitle>
          <DialogContent dividers>
            {solarWatts[0]?
            <div className="table-responsive-material">
            <Table className="default-table table-unbordered table table-sm table-hover">
              <thead className="table-head-sm th-border-b">
                <tr className='center thead-tr'>
                  <th>
                  <CheckCircleOutlineIcon key={'index'} size={'small'} className='m-0 white' style={{float:'left'}}
                  />
                  </th>
                  <th>ID</th>
                  <th>Power (W)</th>
                  <th>Model</th>
                  <th>Voltage (Voc)</th>
                  <th>Current (Isc)</th>
                  <th>Voltage (Vmp)</th>
                  <th>Current (Imp)</th>
                </tr>
              </thead>
              
              <tbody>
                {solarWatts.map((solarWatt, index) => {
                  return <tr key={index} className='center tbody-tr' >
                    <td>
                    <FormControlLabel key={index}
                      control={<Checkbox checked={(solarWatt?.checked==='true')? true:false} onChange={event => handleChangeP(solarWatt?.id, event.target.checked)} name={`name-${solarWatt?.id}`} size={'small'} className='m-0' />}
                    />
                    </td>
                    <td>{index+1}</td>
                    <td>{solarWatt.power}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="user-detail">
                          <h5 className="user-name">{solarWatt.model}</h5>
                        </div>
                      </div>
                    </td>
                    <td>{solarWatt.voltage}</td>
                    <td>{solarWatt.current}</td>
                    <td>{solarWatt.voltageVmp}</td>
                    <td>{solarWatt.currentImp}</td>
                  </tr>
                })}
              </tbody>
            </Table>
            </div>:
            <div className='row justify-content-center '>
              <h3 style={{color: '#f44336'}}>Solar Brand must be changed!</h3>
            </div>}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseP} color="primary" variant="contained" className="pull-right">
              {solarWatts[0]?'Selected':'Close'}
            </Button>
          </DialogActions>
      </Dialog>
      
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth="sm" >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} >
          Brand Managment
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.root}>
            <Stepper style={{ paddingLeft: "0px", paddingRight: "0px",}} alternativeLabel
              activeStep={activeStepBrand}  connector={<ColorlibConnector />}  >
              {stepsBrand.map((label, index) => (
                <Step key={`${label}${index}`}>
                  <StepLabel StepIconComponent={ColorlibStepIcon} > {label} </StepLabel>
                </Step>
              ))}
            </Stepper>

            <div>
              {activeStepBrand === stepsBrand.length ? (
                <CardBox styleName="col-lg-12 customeCard" cardStyle="text-center"
                >
                  <Sliderr className="slick-app-frame " {...options} >
                    {invertor.map((data, index) => {
                      return (
                        <span  key={index} onClick={() => invertorbrand(data.id, data.name, index)} >
                          <div class="slick-slide-item">
                            <div className={toggelactivestyleinvertor(index)} >
                              <div>
                                <img src={`${axios.defaults.baseURL}brand/invertor/${data.image}`} className="img-thumbnail rounded mx-auto d-block imagebrandhieght" alt="Responsive" />
                              </div>
                              <span> {data.country} {getFlag(data.country)} </span>
                              <span style={{float: 'right', cursor: 'pointer'}} onClick={()=> {setBrandDescription(data.discription); setOpenBD(true)}} > <Help /> </span>
                            </div>
                          </div>
                        </span>
                      );
                    })}
                  </Sliderr>
                </CardBox>
              ) : (
                <div>
                  <Typography className={classes.instructions}>
                    {activeStepBrand === 0 ? (
                      <CardBox styleName="col-xl-12 col-lg-12 col-md-12 col-12 customeCard" cardStyle="text-center" >
                        <Sliderr className="slick-app-frame " {...options} >
                          {pump.map((data, index) => {
                            return (
                              <span
                                key={index}
                                onClick={() => pumpbrand( data.id, data.name, index)}>
                                <div class="slick-slide-item">
                                  <div className={toggelactivestylepump(index)} >
                                    <div>
                                      <img src={`${axios.defaults.baseURL}brand/pumpbrand/${data.image}`}  className="img-thumbnail rounded mx-auto d-block imagebrandhieght" alt="Responsive" />
                                    </div>
                                    <span> {data.country} {getFlag(data.country)} </span>
                                    <span style={{float: 'right', cursor: 'pointer'}} onClick={()=> {setBrandDescription(data.discription); setOpenBD(true)}} > <Help /> </span>
                                  </div>
                                </div>
                              </span>
                            );
                          })}
                        </Sliderr>
                      </CardBox>
                    ) : activeStepBrand === 1 ? (
                        <CardBox styleName="col-xl-12 col-lg-12 col-md-12 col-12 customeCard" cardStyle="text-center" >
                          <Sliderr className="slick-app-frame" {...options} >
                            {solar.map((data, index) => {
                              return (
                                <span key={index} >
                                  {visibility ? (
                                    <span className="row justify-content-center">
                                      <Spinner
                                        radius={60}
                                        color={"#3f51b5"}
                                        stroke={3}
                                        visible={visibility}
                                      />
                                    </span>
                                    ) :
                                    <div className="slick-slide-item solar">
                                      <div className={toggelactivestyle(index, data.id)} >
                                        <span onClick={() => { solarbrand(data.id, data.name, index); getSolarWatts(data.id);}}>
                                        <div>
                                          <img src={`${axios.defaults.baseURL}brand/solar/${data.image}`} className="img-thumbnail rounded mx-auto d-block imagebrandhieght img_solar_brand_hieght" alt="Responsive" />
                                        </div>
                                        <span> {data.country} {getFlag(data.country)} </span></span>
                                        <span style={{float: 'right', cursor: 'pointer'}} onClick={()=> {setBrandDescription(data.discription); setOpenBD(true);}} > <Help /> </span>
                                      </div>
                                    </div>
                                  }
                                </span>
                              );
                            })}
                          </Sliderr>
                        </CardBox>
                    ) : activeStepBrand === 2 ? (
                      <CardBox styleName="col-xl-12 col-lg-12 col-md-12 col-12 customeCard" cardStyle="text-center" >
                        <Sliderr className="slick-app-frame " {...options} >
                          {invertor.map((data, index) => {
                            return (
                              <span key={index} onClick={() => invertorbrand(data.id, data.name, index)} >
                                <div class="slick-slide-item">
                                  <div className={toggelactivestyleinvertor(index)} >
                                    <div>
                                      <img src={`${axios.defaults.baseURL}brand/invertor/${data.image}`} className="img-thumbnail rounded mx-auto d-block imagebrandhieght" alt="Responsive" />
                                    </div>
                                    <span> {data.country} {getFlag(data.country)} </span>
                                    <span style={{float: 'right', cursor: 'pointer'}} onClick={()=> {setBrandDescription(data.discription); setOpenBD(true)}} > <Help /> </span>
                                  </div>
                                </div>
                              </span>
                            );
                          })}
                        </Sliderr>
                      </CardBox>
                    ) : (
                      ""
                    )}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div>
            {activeStepBrand === stepsBrand.length ? (
              <div>
                <Button onClick={handleResetBrand} className={classes.button} > Reset </Button>
              </div>
            ) : (
              <div>
                <div>
                  <Button disabled={activeStepBrand === 0} onClick={handleBackBrand} className={classes.button} >
                    Back
                  </Button>

                  <Button variant="contained"  color="primary"
                    disabled={(activeStepBrand === stepsBrand.length - 1) ? true : pumpvalue && activeStepBrand === 0 ? false : solarvalue ? solarSelectWatt? false : true : true } onClick={handleNextBrand} className={classes.button} >
                    Next
                  </Button>
                </div>
              </div>
            )}
          </div>
          {activeStepBrand === stepsBrand.length - 1 ? (
            <Button  onClick={handleClose} color="primary" variant="contained"
              disabled={ activeStepBrand === stepsBrand.length - 1 ? invertorvalue ? false : true : true } >
              Done
            </Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
