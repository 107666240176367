import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import Widget from "components/Widget/index";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
// import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
import Swal from "sweetalert2";
import IntlMessages from "util/IntlMessages";
import Spinner from "react-spinner-material";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "./style.css";
import KnowledgeDialog from "./KnowledgeDialog";
import VideoDialog from "./FileUpload/VideoDialog";

const Knowledge = () => {
  const [visibility, setVisibility] = useState(false);
  const [open, setOpen] = useState(false);
  const [getData, setGetData] = useState(false);
  const [tutorialObj, setTutorialObj] = useState([]);
  const [openVideo, setOpenVedio] = useState({
    open:false, path:'', name:'', videoType:''
  });
  const [tutorials, setTutorials] = useState([]);
  
  useEffect(() => {
    if (!open && !getData) {
        getTutorial();
    }
  }, [open, getData]);

  const getTutorial = async () => {
    setVisibility(true);
    axios
      .get("api/tutorials")
      .then((res) => {
        setVisibility(false);
        setTutorials(res.data);
      })
      .catch((err) => {
        setVisibility(false);
        NotificationManager.error(
          <IntlMessages id="notification.errorMessage" />,
          <IntlMessages id="notification.titleHere" />
        );
      });
  };

  const deleteTutorial = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete("api/tutorials/" + id)
          .then((res) => {
            // setTutorials(res.data)
            setTutorials(tutorials.filter((value) => value.id !== id));
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      }
    });
  };

  const editTutorial = (data) => {
    setOpen(true);
    setTutorialObj(data);
  };
  return (
    <>
        <KnowledgeDialog
            open={open}
            setOpen={setOpen}
            setGetData={setGetData}
            tutorialObj={tutorialObj}
            setTutorialObj={setTutorialObj}
        />
        <VideoDialog
            openVideo={openVideo}
            setOpenVedio={setOpenVedio}
        />

      <Widget styleName={`tableheight`}>
        <div className="d-flex flex-row mb-2">
          <IconButton
            color="primary"
            aria-label="Add Slider Image"
            component="span"
            onClick={() => setOpen(true)}
            hidden={open === true}
          >
            <AddCircleOutlineIcon />
          </IconButton>
          <span hidden={open === true} style={{ padding: '12px' }}><h2 class="center m-0">Tutorail Managment</h2></span>
        </div>

        <span className="row justify-content-center">
          <Spinner
            radius={60}
            color={"#3f51b5"}
            stroke={3}
            visible={visibility}
          />
        </span>
        <div className="table-responsive-material">
          <Table className="default-table table-unbordered table table-sm table-hover ">
            <thead className="table-head-sm th-border-b">
            <tr>
                <th >No:</th>
                <th >Name</th>
                <th width='40%'>Image</th>
                <th>Type</th>
                <th>Status</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
              {tutorials.map((data, index) => {
                return (
                  <tr key={index} className={`${data.status==='inactive'?'inactive':''}`}>
                    <td>{index + 1}</td>
                    <td>{data.name}</td>
                    <td>
                        <div className="d-flex align-items-center">
                            <img src={`${axios.defaults.baseURL}tutorial/image/${data.image}`}  class="img-thumbnail rounded acc_img_width1"  alt="Responsive" />
                        </div>
                    </td>
                    <td>{data.type==='pdf'?
                    <a disabled={data?.file_src?false:true} href={`${axios.defaults.baseURL}tutorial/${data?.file_src}`} target="_blank">
                      <IconButton
                      size="small"
                      aria-label="PDF"
                      color="primary">
                        <PictureAsPdfIcon />
                      </IconButton>
                   </a> :
                    data.type==='video'?
                    <IconButton
                     size="small"
                     aria-label="Play"
                     color="primary"
                     onClick={() => setOpenVedio({
                      ...openVideo, open:true, path:data?.file_src, name:data?.name, videoType:data?.video_type
                    })}
                   >
                     <PlayCircleFilledIcon />
                   </IconButton>:
                   data.type
                  }</td>
                    <td>{data.status}</td>
                    <td>
                      <IconButton
                        size="small"
                        aria-label="Delete"
                        color="secondary"
                        onClick={() => {
                          deleteTutorial(data.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        aria-label="Edit"
                        color="primary"
                        disabled={open}
                        onClick={() => editTutorial(data)}
                      >
                        <EditIcon />
                      </IconButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Widget>
      <NotificationContainer />
    </>
  );
};

export default Knowledge;
