import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import { ReportProblem } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import { UncontrolledAlert } from "reactstrap";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import SelectedPumpDia from './SelectedPumpDia'
//form
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
//backdrop
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
//country flag
import Flags from "country-flag-icons/react/3x2";
//chart iports
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, } from "recharts";

//alert
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  backgroundaccordiancolor: {
    backgroundColor: "#00000008",
    minHeight: 'auto !important',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  rootalert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function getFlag(countryname) {
  switch (countryname) {
    case "Afghanistan":
      return <Flags.AF title="Afghanistan" className="customflag" />;
    case "Italy":
      return <Flags.IT title="Italy" className="customflag" />;
    case "China":
      return <Flags.CH title="China" className="customflag" />;
    case "Iran":
      return <Flags.IR title="Iran" className="customflag" />;
    case "Turkey":
      return <Flags.TR title="Turkey" className="customflag" />;
    case "Germany":
      return <Flags.DE title="Germany" className="customflag" />;
    default:
      return "";
  }
}

export default function Analyze(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel1");
  const {setDeviceCost, pumpConfigId, setPumpConfigId, brandListId, setBrandListId}= props;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
 
  
 
  const [openbackdrop, setOpenbackdrop] = React.useState(false);
  
  const [dataError, setDataError] = useState(false);
  const [first1, setFirst1] = useState(true);
  const [inputError1, setInputError1] = useState({});

  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState(false);
  const [selectedPumps, setSelectedPumps] = useState([]);
  const [selectedPump, setSelectedPump] = useState([]); 
  const {filled, setFilled} = props;
  const docalculculation = async() => {
    setOpenbackdrop(true);
    // console.log("props evaluationdata: ", props.evaluationdata);
    axios
      .post("api/project-analyze", props.evaluationdata)
      .then((res) => {
        // console.log(res.data.pumplists);
        setFirst1(false);
        if(res.data.pumplists[0]){
          setSelectedPumps(res.data.pumplists);
          setResponse(true);
        }else{
          setSelectedPumps([]);
          setSelectedPump([]);
          setPumpConfigId('');
          setFilled(false);
          setResponse(false);
        }
        setOpen(true);
        setOpenbackdrop(false);
      })
      .catch((err) => {
        setFirst1(false);
        setSelectedPumps([]);
        setOpenbackdrop(false);
        setDataError(true);
        setSelectedPumps([]);
        NotificationManager.error(
          <IntlMessages id="notification.errorMessage" />,
          <IntlMessages id="notification.titleHere" />
        );
      });
      // console.log('res data errors AFTER', inputError);
  };
  
  useEffect(() => {
    if (props.evaluationdata) {
      docalculculation();
    }
    // if (props.citylocation) {
    //   getIrredation();
    // }
  }, [props.evaluationdata]);
  useEffect(() => {
    if(selectedPump?.id && !open){
      // console.log('selected pump', selectedPump);
      if((selectedPump?.errors?.head == true) || (selectedPump?.errors?.discharge == true) || (selectedPump?.errors?.motorcable == true) || (selectedPump?.errors?.pumpvalue == true) || (selectedPump?.errors?.solarvalue == true) || (selectedPump?.errors?.invertorvalue == true)){
          setInputError1(selectedPump?.errors);
          // console.log('res data errors', inputError1);
          // console.log('res data errors inputError', props.inputError);
          // console.log('res data errors 1', selectedPump.errors);
          setDataError(true);
          setFilled(false);
          NotificationManager.error(
            <IntlMessages id="notification.errorMessage" />,
            <IntlMessages id="notification.titleHere" />
          );
      }else{
        setInputError1(selectedPump?.errors);
        setDataError(false);
        setFilled(true);
        NotificationManager.success(
          <IntlMessages id="notification.successMessage" />,
          <IntlMessages id="notification.titleHere" />
        );
      }
    }
  }, [selectedPump, open]);

  useEffect(() => {
    // return ()=>{
     if(!selectedPumps[0] && !response && !filled){
            if(!first1){
              handleError(true);
            }
            // console.log('inside if !selectedPumps[0]', !selectedPumps[0]);
      }else{
        // console.log('input Error before set inside selectedPump?.id || !pumpConfigId', inputError1);
        handleError(false);
        // console.log('inside else !selectedPumps[0]', !selectedPumps[0]);
      }
    // }
  }, [selectedPumps, selectedPump, pumpConfigId, response, filled]);

  const handleError = (err) =>{
    setDataError(err);
        props.setInputError({
          dirtloss: err,
          discharge: err,
          dynamicHead: err,
          head: err,
          invertorvalue: err,
          motorcable: err,
          piplenght: err,
          pumpvalue: err,
          solarvalue: err});
        setInputError1({
            dirtloss: err,
            discharge: err,
            dynamicHead: err,
            head: err,
            invertorvalue: err,
            motorcable: err,
            piplenght: err,
            pumpvalue: err,
            solarvalue: err});
  }
  useEffect(() => {
    if(pumpConfigId != 0){
      // console.log('pumpListId', selectedPump?.id);
      // console.log('solarListId', selectedPump?.solar?.solar_list?.id);
      
      props.setBrandListId({
        pumpListId:selectedPump?.id, solarListId: selectedPump?.solar?.solar_list?.id, inverterListId:selectedPump?.inverter?.id
      });
      // console.log('bran list id', brandListId);
    }
  }, [pumpConfigId, brandListId?.pumpListId]);

  useEffect(() => {
    return ()=>{
      // console.log('input Error before set', inputError1);
      if(!openbackdrop){
        props.setInputError(inputError1);
      }
    };
  }, [inputError1, props.inputError]);
  useEffect(() => {
    var pumpCost = selectedPump?.price?selectedPump?.price:0;
    var solarCost = selectedPump?.solar?.solar_list?.price?Number(selectedPump?.solar?.solar_list?.price)*Number(selectedPump?.solar.solar_quantity):0;
    var inverterCost = selectedPump?.inverter?.price?selectedPump?.inverter?.price:0;
    var structureCost = selectedPump?.structure?.price?Number(selectedPump?.structure?.price)*Number(selectedPump?.solar.solar_quantity):0;

    var motorCablePrice = selectedPump?.pump_config_cable_price?Number(selectedPump?.pump_config_cable_price)*Number(props.evaluationdata?.motorcable):0;

    // if(selectedPump?.pipe_length_config){console.log('pipelengthPrice', selectedPump?.pipe_length_config[0]?.pipe_length.price)}
    var pipelengthPrice = selectedPump?.pipe_length_config?Number(selectedPump?.pipe_length_config[0]?.pipe_length.price)*Number(props.evaluationdata?.piplenght):0
    
    var solarCablePrice = selectedPump?.solar?.solar_list?.cable?.price?Number(selectedPump?.solar?.solar_list?.cable?.price)*Number(props.evaluationdata?.solarCable):0;

    var totalPrice = Number(pumpCost)+(Number(solarCost))+Number(inverterCost)+Number(structureCost)+Number(motorCablePrice)+Number(solarCablePrice)+Number(pipelengthPrice);
    setDeviceCost(Number(totalPrice));
    // console.log('deviceCost', Number(totalPrice));
    // console.log('structureCost', Number(structureCost));
    setPumpConfigId(selectedPump.pump_config?selectedPump.pump_config[0]?.id:'');
    // console.log('setPumpConfigId', pumpConfigId);
    
   }, [selectedPump, props.evaluationdata, pumpConfigId]);
  

 
  return (
    <div className="row m-1">
      {selectedPumps[0]? 
        <SelectedPumpDia open={open} setOpen={setOpen} selectedPumps={selectedPumps} setSelectedPumps={setSelectedPumps} selectedPump={selectedPump} setSelectedPump={setSelectedPump}></SelectedPumpDia>
        :'' 
      }
      {dataError ? (
        <div className="row justify-content-center " style={{ margin: "auto" }}>
          <UncontrolledAlert className="alert-addon-card bg-danger bg-danger text-white shadow-lg justify-content-center">
            <span className="icon-addon alert-addon">
              <ReportProblem />
              {/* <i className="zmdi zmdi-danger zmdi-hc-fw zmdi-hc-lg" /> */}
            </span>

            <h2 className="d-inline-block">
              Sorry! The system doesn't generate result with these specification. Please try again!
              <br/>
              <ul>
              {inputError1?.pumpvalue? 
              <li>Pump Brand must be changed!</li>: ''
              }
              {inputError1?.solarvalue? 
              <li>Solar Brand must be changed!</li>: ''
              }
              {inputError1?.invertorvalue? 
              <li>Inverter Brand must be changed!</li>: ''
              }
              {inputError1?.head? 
              <li>Head must be resized!</li>: ''
              }
              {inputError1?.motorcable? 
              <li>Motorcable must be resized!</li>: ''
              }
              {inputError1?.head? 
              <li>Pipe-length must be resized!</li>: ''
              }
              {inputError1?.discharge? 
              <li>Water Demand must be resized!</li>: ''
              }
              </ul>
               
            </h2>
          </UncontrolledAlert>
        </div>
      ) : (
        ""
      )}
      <div className="col-md-12">
        <h2>Project Name : {props.projectname} </h2>{" "}
      </div>
      <Backdrop className={classes.backdrop} open={openbackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {filled ? (
        <div className={classes.root}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className={classes.backgroundaccordiancolor}
            >
              <Typography className={classes.heading}>Output [m³/h]</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="col-md-7">
                <ResponsiveContainer width="100%" height={250}>
                  <BarChart data={selectedPump?.monthlyHrOutput} margin={{ top: 10, right: 0, left: -25, bottom: 0 }}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="MonthlyOutput" fill="#3367d6" margin={{ top: 2}}/>
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className="col-md-5">
                <ResponsiveContainer width="100%" height={250}>
                  <BarChart data={selectedPump?.hrOutputP} margin={{ top: 10, right: 0, left: -25, bottom: 0 }}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="HrOutput" fill="#3367d6" margin={{ top: 2}}/>
                  </BarChart>
                </ResponsiveContainer>
              </div>

              {/* end chart */}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              className={classes.backgroundaccordiancolor}
            >
              <Typography className={classes.heading}>Irradiations [kwh/m2]</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <div className="col-md-7">
                <ResponsiveContainer width="100%" height={250}>
                  <BarChart data={selectedPump?.irradiation?.monthIrrs} margin={{ top: 10, right: 0, left: -25, bottom: 0 }}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Value" fill="#ffc658" margin={{ top: 2}}/>
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className="col-md-5">
                {/* <span class="badge badge-primary ml-4">Average = 50</span> */}
                <ResponsiveContainer width="100%" height={250}>
                  <BarChart data={selectedPump?.irradiation?.dailyIrrs} margin={{ top: 10, right: 0, left: -25, bottom: 0 }}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Value" fill="#ffc658" margin={{ top: 2}}/>
                  </BarChart>
                </ResponsiveContainer>
              </div>

              {/* end chart */}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
              className={classes.backgroundaccordiancolor}
            >
              <Typography className={classes.heading}>Energy [kwh]</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* start char */}
              <div className="col-md-7">
                <ResponsiveContainer width="100%" height={250}>
                  <BarChart data={selectedPump?.energy} margin={{ top: 10, right: 0, left: -25, bottom: 0 }}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Energy" fill="#FF0000" margin={{ top: 2}}/>
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className="col-md-5">
                <ResponsiveContainer width="100%" height={250}>
                  <BarChart data={selectedPump?.hrEnergy} margin={{ top: 10, right: 0, left: -25, bottom: 0 }}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="HrEn" fill="#FF0000" margin={{ top: 2}}/>
                  </BarChart>
                </ResponsiveContainer>
              </div>

              {/* end chart */}
            </AccordionDetails>
          </Accordion>
          <div className="mt-2"></div>
          <div>
            
            <div className="row row_ustify">
              <div className="col-md-2 pr-0"><strong> Water Pump </strong></div>
              <div className="col-md-10 pl-0">
                {selectedPump ? (
                  <>
                    
                    <div className="row">
                      <div className="col-md-3">
                        <strong>Made in {selectedPump?.pump_brand?.country} </strong>
                        {getFlag(selectedPump?.pump_brand?.country)}{" "}
                      </div>
                      <div className="col-md-3">
                        <strong>Brand: {selectedPump?.pump_brand?.name}</strong>
                      </div>
                      <div className="col-md-3">
                        <strong>Model: {selectedPump?.model}</strong>
                      </div>
                      <div className="col-md-3" style={{paddingRight: '10px', paddingLeft: '10px'}}>
                          <FormControl fullWidth>
                          <a href={`${axios.defaults.baseURL}brand/pumpbrand/pump_list/data_sheet/${selectedPump?.data_sheet}`} target="_blank">
                          <Button
                              style={{padding: '0px 6px'}}
                              variant="contained"
                              color="default"
                              className={classes.button}
                              startIcon={<CloudDownloadIcon />}
                            >
                              Data Sheet
                            </Button>
                          </a>
                            
                          </FormControl>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <strong>Power: {selectedPump?.r_power?.kw} KW</strong>
                      </div>
                      <div className="col-md-3">
                        <strong>Current: {selectedPump?.ampeier} A</strong>{" "}
                      </div>
                      <div className="col-md-3">
                        <strong>Cable: {selectedPump?.r_power?selectedPump?.r_power?.power_cable_length[0]?.cable_type?.name:''}</strong>{" "}
                      </div>
                    </div>
                  
                  </>
                ) : (
                  ""
                )}

                {selectedPump ? (
                  ""
                ) : (
                  <div className={classes.rootalert}>
                    <Alert severity="error">Water Pump Not found !</Alert>
                  </div>
                )}
              </div>
            </div>
            <Divider className="mb-2 mt-2" />
            <div className="row row_ustify">
              <div className="col-md-2 pr-0"><strong>Solar</strong></div>
              <div className="col-md-10 pl-0">
                {selectedPump?.solar ? (
                  <>
                    <div className="row">
                      <div className="col-md-3">
                        <strong>Made in {selectedPump?.solar?.solar_list?.solar_brand?.country} </strong>{" "}
                        {getFlag(selectedPump?.solar?.solar_list?.solar_brand?.country)}{" "}
                      </div>
                      <div className="col-md-3">
                        <strong>Brand: {selectedPump?.solar?.solar_list?.solar_brand?.name}</strong>{" "}
                      </div>
                      <div className="col-md-3">
                        <strong>Model: {selectedPump?.solar?.solar_list?.model}</strong>{" "}
                      </div>
                      <div className="col-md-3" style={{paddingRight: '10px', paddingLeft: '10px'}}>
                          <FormControl fullWidth>
                          <a href={`${axios.defaults.baseURL}brand/solar/solar_list/data_sheet/${selectedPump?.solar?.solar_list?.data_sheet}`} target="_blank">
                          <Button
                              style={{padding: '0px 6px'}}
                              variant="contained"
                              color="default"
                              className={classes.button}
                              startIcon={<CloudDownloadIcon />}
                            >
                              Data Sheet
                            </Button>
                          </a>
                            
                          </FormControl>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <strong>Power: {selectedPump?.solar?.solar_list?.power} W</strong>{" "}
                      </div>
                      <div className="col-md-3">
                        <strong>Current: {selectedPump?.solar?.solar_list?.current} A</strong>
                      </div>
                      <div className="col-md-3">
                        <strong>Quantity: {selectedPump?.solar?.solar_quantity} panel</strong>{" "}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <Divider className="mb-2 mt-2" />
            <div className="row row_ustify">
              <div className="col-md-2 pr-0"><strong>Inverter</strong></div>
              <div className="col-md-10 pl-0">
                {selectedPump?.inverter ? (
                  <>
                    
                    <div className="row">
                      <div className="col-md-3">
                        <strong>Made in {selectedPump?.inverter?.invertor_brand?.country} </strong>{" "}
                        {getFlag(selectedPump?.inverter?.invertor_brand?.country)}{" "}
                      </div>
                      <div className="col-md-3">
                        <strong>Brand: {selectedPump?.inverter?.invertor_brand?.name}</strong>{" "}
                      </div>
                      <div className="col-md-3">
                        <strong>Model: {selectedPump?.inverter?.model}</strong>{" "}
                      </div>
                      <div className="col-md-3" style={{paddingRight: '10px', paddingLeft: '10px'}}>
                          <FormControl fullWidth>
                          <a href={`${axios.defaults.baseURL}brand/invertor/invertor_list/data_sheet/${selectedPump?.inverter?.data_sheet}`} target="_blank">
                          <Button
                              style={{ padding: '0px 6px'}}
                              variant="contained"
                              color="default"
                              className={classes.button}
                              startIcon={<CloudDownloadIcon />}
                            >
                              Data Sheet
                            </Button>
                          </a>
                            
                          </FormControl>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <strong>Power: {selectedPump?.inverter?.power} KW</strong>{" "}
                      </div>
                      <div className="col-md-3">
                        <strong>Current: {selectedPump?.inverter?.current} A</strong>
                      </div>
                      <div className="col-md-3">
                        <strong>Voltage: {selectedPump?.inverter?.voltage} V</strong>{" "}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {selectedPump?.inverter ? (
                  ""
                ) : (
                  <div className={classes.rootalert}>
                    <Alert severity="error">Inverter Not found !</Alert>
                  </div>
                )}
              </div>
            </div>
            <Divider className="mb-2 mt-2" />
            <div className="row">
              <div className="col-md-2 pr-0"><strong>Structure</strong></div>
              <div className="col-md-10 pl-0">
                {selectedPump?.structure? (
                  <>
                    <div className="row">
                      {/* <div className="col-md-3">
                        <strong>Name: {structure?.name}</strong>{" "}
                      </div> */}
                      <div className="col-md-6">
                        <strong>Model: {selectedPump?.structure?.model}</strong>{" "}
                      </div>
                      <div className="col-md-3">
                        <strong>Quantity: {selectedPump?.solar?.panal_quantity} stand</strong>{" "}
                      </div>
                      <div className="col-md-3" style={{paddingRight: '10px', paddingLeft: '10px'}}>
                          <FormControl fullWidth>
                          <a href={`${axios.defaults.baseURL}structure/data_sheet/${selectedPump?.structure?.datasheet}`} target="_blank">
                          <Button
                              style={{ padding: '0px 6px'}}
                              variant="contained"
                              color="default"
                              className={classes.button}
                              startIcon={<CloudDownloadIcon />}
                            >
                              Data Sheet
                            </Button>
                          </a>
                            
                          </FormControl>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
            
      ) : (
        ""
      )}
      <NotificationContainer />
    </div>
  );
}
