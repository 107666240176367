import React, { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Table} from 'reactstrap';
import { makeStyles } from '@material-ui/styles';
import axios from "axios";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: 'space-between',
  },
}))(MuiDialogActions);

  
const useStyles = makeStyles({
  root: {
    // '& .super-app-theme--header': {
    //   backgroundColor: '#5f77ffa6',
    // },
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: '#5f77ffa6',
    },
    '& .Mui-odd': {
      backgroundColor: '#e4e4e4',
    },
  },
});

export default function SelectedPumpDia(props) {
  const { open, setOpen, selectedPumps, setSelectedPumps, selectedPump, setSelectedPump} = props;
  const [openGraph, setOpenGraph] = useState(false);
  const [graph, setGraph] = useState('');

  const classes = useStyles();

  const handleCloseGraph = () => {
      setOpenGraph(false);
  };

  const handleClose = () => {
    if(selectedPump?.id){
      setOpen(false);
    }
  };

  //drop down
  const handleChangeP = (id, value) => {
    // console.log('pumpBrand', value);
    const newSelectedPumps = selectedPumps.map(i => {
      if(i.id === id){
        i.checked = value? "true":"false";
        setSelectedPump(i);
      }else{
        i.checked = "false";
      }
      return i;
    })
    setSelectedPumps(newSelectedPumps);
  };

  return (
    <div>
<Dialog onClose={handleCloseGraph} aria-labelledby="customized-dialog-title" open={openGraph} maxWidth="md" fullWidth="md">
        <DialogTitle id="customized-dialog-title" onClose={handleCloseGraph}>
          CHARACTERISTIC CURVES AND PERFORMANCE DATA (50 Hz  n=2900 1/min)
        </DialogTitle>
          <DialogContent dividers >
            <div className='row'>
                <div className='col-md-12 graph-img-md'>
                <img src={`${axios.defaults.baseURL}brand/pumpbrand/pump_list/graph/${graph}`} className='img-thumbnail rounded d-block m-a' />
                </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseGraph} color="primary" variant="contained" className="pull-right">
              Close
            </Button>
          </DialogActions>
      </Dialog>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="md" fullWidth="md">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Best Matches/Suggested Submersible
        </DialogTitle>
          <DialogContent dividers>
          <div className="table-responsive-material">
          <Table className="default-table table-unbordered table table-sm table-hover">
            <thead className="table-head-sm th-border-b">
              <tr className='center thead-tr' >
                <th>
                <CheckCircleOutlineIcon key={'index'} size={'small'} className='m-0 white' style={{float:'left'}}
                />
                </th>
                <th>ID</th>
                <th>Flow Graph</th>
                <th>Pump Model</th>
                <th>Power(KW)</th>
                <th>Power(HP)</th>
                <th>Head (metres)</th>
                <th>Flow rate Q (m³/h)</th>
                <th>PV Modules</th>
                <th>PV needed (panels)</th>
                <th>PV needed (kw)</th>
                <th>Av. Water (m³/day)</th>
                <th>Av. Water (m³/year)</th>
              </tr>
            </thead>
            
            <tbody>
              {selectedPumps.map((selPump, index) => {
                return <tr key={index} className='center tbody-tr' >
                  <td>
                  <FormControlLabel key={index}
                    control={<Checkbox checked={(selPump?.checked==='true')? true:false} onChange={event => handleChangeP(selPump?.id, event.target.checked)} name={`name-${selPump?.id}`} size={'small'} className='m-0' />}
                  />
                  </td>
                  <td>{index+1}</td>
                  <td><img src={`${axios.defaults.baseURL}layouts/${selPump?.img}`} width={40} onClick={()=>{ setGraph(selPump?.graph); setOpenGraph(true);}} /></td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="user-detail">
                        <h5 className="user-name">{selPump.model}</h5>
                      </div>
                    </div>
                  </td>
                  <td>{selPump.r_power?.kw}</td>
                  <td>{selPump.hp}</td>
                  <td>{selPump.head}</td>
                  <td>{selPump.discharge}</td>
                  <td>{selPump.pv_module}w</td>
                  <td>{selPump.pv_needed_pa}</td>
                  <td>{selPump.pv_needed_kw}</td>
                  <td>{selPump.av_water_day}</td>
                  <td>{selPump.av_water_year}</td>
                </tr>
              })}
            </tbody>
          </Table>
          </div>
          </DialogContent>
          <DialogActions>
            <span>Select one of the most effective pump for further process.</span>
            <Button onClick={handleClose} color="primary" variant="contained" className="pull-right">
              Done
            </Button>
          </DialogActions>
      </Dialog>
    </div>
  );
}
