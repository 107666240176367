import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {FormControl,RadioGroup,FormControlLabel,Radio} from '@material-ui/core';
// form dependency
import { TextField } from '@material-ui/core';
import axios from 'axios';
import { useForm } from 'react-hook-form';
//drop zoon
import { NotificationContainer, NotificationManager } from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import FileUpload from './FileUpload';
import CustomDropzone from './FileUpload/CustomDropzone';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function KnowledgeDialog(props) {
  const { register, handleSubmit, errors } = useForm(); // initialize the hook
  const { open, setOpen, setGetData } = props;
  const { tutorialObj, setTutorialObj } = props;
  const [status, setStatus] = useState("active");
  const [language, setLanguage] = useState("English");
  const [description, setDescription] = useState("");
  const [fileSrc, setFileSrc] = useState({
    old: "",
    filePath: "tutorial/",
    btnText: "Tutorial File",
  });
  const [image, setImage] = useState({
    oldImage: "",
    filePath: "tutorial/image/",
    btnText: "Tutorial Image",
  });
  let fileSrcVar = "";
  let fileSizeVar = "";
  let imageFile = "";
  const eventhandlerDaSh = (data) => {
    fileSrcVar = data[0];
    fileSizeVar = data[1];
    // console.log('fileSrcVar file', fileSrcVar);
  };

  const eventhandlerIm = (data) => {
    imageFile = data;
    // console.log('images file data', data);
    // console.log('images file', imageFile);
  };
  const handleClose = () => {
    emptyForm(null);
    setGetData(true);
    setOpen(false);
  };
  const emptyForm = () => {
    setTutorialObj(null);
    setStatus('active');
    setLanguage('English');
    setImage({ ...image, oldImage: "" });
    setFileSrc({ ...fileSrc, old: "" });
  };
  useEffect(() => {
    setStatus(tutorialObj?.status ? tutorialObj?.status : 'active');
    // inhere
    setDescription(tutorialObj?.description);
    setLanguage(tutorialObj?.language?tutorialObj?.language:'English');
    setImage({
      ...image,
      oldImage: tutorialObj?.image ? tutorialObj?.image : "",
    });
    setFileSrc({
      ...fileSrc,
      old: tutorialObj?.file_src ? tutorialObj?.file_src : "",
    });
     
     
  }, [tutorialObj, props.open]);
  const onSubmit = (data) => {
    data['description'] = description;
    data['status'] = status;
    data['src'] = fileSrcVar;
    data['filesize'] = fileSizeVar;
    data['language'] = language;
    data['tutorial_image'] = imageFile;
    console.log('data in post form', data);
    var id = JSON.parse(localStorage.getItem("UserData"))?.id;
    data['user_id'] = id;
      axios.post('api/tutorials', data)
        .then(res => {
          // console.log('result file name: ', res.data.extension);
          NotificationManager.success(<IntlMessages id="notification.successMessage" />, <IntlMessages
            id="notification.titleHere" />);
          setGetData(false);
          setOpen(false)
        }).catch(err => {
          NotificationManager.error(<IntlMessages id="notification.errorMessage" />, <IntlMessages
            id="notification.titleHere" />);
        });
  };


  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="xs" fullWidth="xs">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Knowledge Form
        </DialogTitle>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers>
            <div className="row">
              <div className="col-xl-6 col-gl-6 col-md-6 col-sm-12 col-12 py-3">
                    <TextField id="id" type='hidden' name="id" defaultValue={(tutorialObj?.id) ? tutorialObj?.id : 0} inputRef={register} />
                    <TextField id="name" className="form-control" name="name" defaultValue={tutorialObj?.name} label="Name" size="small" variant="outlined" inputRef={register({ required: true })}
                      error={errors.name && true} helperText={errors.name && '*required'}
                    />
              </div>
              <div className={`col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-3`}>
                <FormControl component="fieldset" variant="outlined" className="form-control"  size="small">
                  <RadioGroup size="small" className="d-flex flex-row" aria-label="status" error={errors.status && true} helperText={errors.status && '*required'}
                      name="status" defaultValue={status} onChange={e=>setStatus(e.target.value)}>
                    <FormControlLabel value="inactive" control={<Radio color="primary"/>} label="Inactive"/>
                    <FormControlLabel value="active" control={<Radio color="primary"/>} label="Active"/>
                  </RadioGroup>
                </FormControl>
              </div>
              <div className={`col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-3`}>
                <FormControl component="fieldset" variant="outlined" className="form-control"  size="small">
                  <RadioGroup size="small" className="d-flex flex-row" aria-label="language" error={errors.language && true} helperText={errors.language && '*required'}
                      name="language" defaultValue={language} onChange={e=>setLanguage(e.target.value)}>
                    <FormControlLabel value="English" control={<Radio color="primary"/>} label="English"/>
                    <FormControlLabel value="Dari" control={<Radio color="primary"/>} label="Dari"/>
                    <FormControlLabel value="Pashto" control={<Radio color="primary"/>} label="Pashto"/>
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                <div class="form-group">
                  <div class="form-group">
                    <textarea class={`form-control form-control-lg`} id='description' name='description' value={description} onChange={e => setDescription(e.target.value)} rows="2" spellcheck="false" placeholder="Short Description"></textarea>
                  </div>
                  {errors.description?
                  <span className='error'>*required</span>
                  :
                  ''}
                  
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-3 waterPumFile waterPumpListFile">
                  <FileUpload
                    formData={fileSrc}
                    // setFileSize={setFileSize}
                    onChange={eventhandlerDaSh.bind(this)}
                  />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-3 waterPumFile waterPumpListFile">
                  <CustomDropzone
                    formData={image}
                    onChange={eventhandlerIm.bind(this)}
                  />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary" variant="contained" className="pull-right">
              Save Tutorail
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <NotificationContainer />
    </div>
  );
}
